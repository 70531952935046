import React from "react";
import Layout from "../../../components/Layout";
import PageHeader from "../../../components/PageHeader";
import Conditions from "../../../components/Conditions";
import HemorrhoidsInfo from "../../../components/resources/HemorrhoidsInfo";

const Hemorrhoids = () => {
  return (
    <Layout>
      <PageHeader text="Hemorrhoids" />
      <HemorrhoidsInfo />
      <Conditions />
    </Layout>
  );
};

export default Hemorrhoids;
