import React from "react";

const HemorrhoidsInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-xl text-gray-800 mb-2">What are hemorrhoids?</p>
        <p className="text-md text-gray-600 mb-2">
          Hemorrhoids are swollen blood vessels in and around the anus and lower
          rectum that stretch under pressure; similar to varicose veins in the
          legs.
        </p>
        <p className="text-md text-gray-600 mb-2">
          The increased pressure and swelling may result from straining to move
          the bowel, pregnancy, heredity, aging, and chronic constipation or
          diarrhea.
        </p>
        <p className="text-md text-gray-600 mb-12">
          Hemorrhoids are either inside the anus(internal) or under the skin
          around the anus(external).
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What are the symptoms of hemorrhoids?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Although many people have hemorrhoids, not all experience symptoms.
          The most common symptom of internal hemorrhoids is bright red blood
          covering the stool, on toilet paper, or in the toilet bowl. However,
          an internal hemorrhoid may protrude through the anus outside the body,
          becoming irritated and painful. This is known as a protruding
          hemorrhoid.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Symptoms of external hemorrhoids may include itching, painful
          swelling, or a hard lump around the anus that results when a blood
          clot forms.
        </p>
        <p className="text-md text-gray-600 mb-12">
          In addition, excessive straining, rubbing, or cleaning around the anus
          may cause irritation with bleeding and/or itching , which may produce
          a vicious cycle of symptoms. Draining mucus may also cause itching.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          How are hemorrhoids diagnosed?
        </p>
        <p className="text-md text-gray-600 mb-12">
          A thorough evaluation and proper diagnosis by the doctor is important
          any time bleeding from the rectum or blood in the stool lasts more
          than a couple of days. Bleeding may also be a symptom of other
          digestive diseases, including colorectal cancer.
        </p>

        <p className="text-xl text-gray-800 mb-2">What is the treatment?</p>
        <p className="text-md text-gray-600 mb-2">
          Medical treatment of hemorrhoids initially is aimed at relieving
          symptoms. Measures to reduce symptoms include:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>
            Warm tub of sitz baths several times a day in plain, warm water for
            about 10 minutes.
          </li>
          <li>Ice packs to help reduce swelling.</li>
          <li>
            Application of hemorrhoidal cream or suppository to the affected
            area for a limited time.
          </li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          Treatment of hemorrhoids is aimed at changing conditions associated
          with the pressure and straining of constipation. Doctors will often
          recommend increasing fiber and fluids in the diet. Eating the right
          amount of fiber and drinking six to eight glasses of fluid (not
          alcohol) result in softer, bulkier stools. A softer stool makes
          emptying the bowels easier and lessens the pressure on hemorrhoids
          caused by straining. Elimination of straining also helps prevent the
          hemorrhoids from protruding.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Good sources of fiber are fruits, vegetables, and whole grains. In
          addition, doctors may suggest a bulk stool softener or a fiber
          supplement.
        </p>
        <p className="text-md text-gray-600 mb-12">
          In some cases, hemorrhoids must be treated surgically. These methods
          are used to shrink and destroy the hemorrhoidal tissue and are
          performed under anesthesia by a cob-rectal surgeon or a general
          surgeon.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          How are hemorrhoids prevented?
        </p>
        <p className="text-md text-gray-600 mb-12">
          The best way to prevent hemorrhoids is to keep stools soft so they
          pass easily, thus decreasing pressure and straining, and to empty
          bowels as soon as possible after the urge occurs. Exercise, including
          walking, and increased fiber in the diet help reduce constipation and
          straining by producing stools that are softer and easier to pass. In
          addition, a person should not sit on the toilet for a long period of
          time.
        </p>
      </div>
    </div>
  );
};

export default HemorrhoidsInfo;
